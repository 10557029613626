<template>
  <div
    :class="{
      'unselectable-text':
        (user && user.groups.includes(7)) || preview_evaluation,
    }"
  >
    <div v-if="isLoading">
      <b-card>
        <b-skeleton animation width="85%"></b-skeleton>
        <b-skeleton animation width="55%"></b-skeleton>
        <b-skeleton animation width="70%"></b-skeleton>
      </b-card>
    </div>
    <div v-else class="question-container">
      <div
        v-if="allows_crud"
        class="question-move px-1 handle"
        :class="{ 'grabbable-cursor': allows_crud }"
      >
        <b-icon class="move-icon" icon="arrows-expand" scale="1.3"></b-icon>
      </div>

      <div class="container-content-question">
        <!-- Container Header -->
        <div class="container-header">
          <div class="container-body-card">
            <div class="question-body max-width-print max-width d-flex">
              <div class="row-div">
                <strong>
                  <span v-if="testSegment">{{ testSegment.order }}.</span
                  >{{ Question.order }}
                </strong>
              </div>
              <div
                class="w-100 rich-text-content"
                v-b-tooltip.top.noninteractive.v-secondary="
                  `${
                    allows_crud && !Question.is_locked
                      ? `Editar el ${$getVisibleNames(
                          'manual.test.enunciado',
                          false,
                          'Enunciado'
                        )}`
                      : ''
                  }`
                "
                :class="{
                  'title-container': allows_crud && !Question.is_locked,
                }"
                @click="showModalQuestion()"
                v-html="Question.title"
              ></div>
            </div>
            <div :class="{ 'container-buttons-allow-cruds': allows_crud }">
              <b-button
                v-if="
                  !Question.is_locked &&
                  allows_crud &&
                  user &&
                  !user.groups.includes(6)
                "
                variant="primary"
                size="sm"
                class="lock-btn p-0 mr-1"
                tabindex="-1"
                v-b-tooltip.top.noninteractive.v-secondary="'Bloquear Pregunta'"
                @click="changeStateQuestion()"
              >
                <b-icon-unlock-fill></b-icon-unlock-fill>
              </b-button>
              <b-button
                v-if="
                  Question.is_locked &&
                  allows_crud &&
                  user &&
                  !user.groups.includes(6)
                "
                variant="primary"
                size="sm"
                class="lock-btn p-0 mr-1"
                tabindex="-1"
                v-b-tooltip.top.noninteractive.v-secondary="
                  'Desbloquear Pregunta '
                "
                @click="changeStateQuestion()"
              >
                <b-icon-lock-fill></b-icon-lock-fill>
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="edit-btn mr-1 p-0"
                v-if="allows_crud && !Question.is_locked"
                v-b-tooltip.top.noninteractive.v-secondary="'Editar Pregunta'"
                tabindex="-1"
                @click="
                  $bvModal.show(
                    `modal-edit-question-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
                  )
                "
              >
                <b-icon-pencil-square></b-icon-pencil-square>
              </b-button>
              <!-- Copiar Pregunta -->
              <template v-if="!copy_button_tem_check">
                <b-button
                  variant="primary"
                  size="sm"
                  class="edit-btn mr-1 p-0"
                  v-if="allows_crud && !Question.is_locked"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Duplicar Pregunta'
                  "
                  @click="copyQuestion"
                >
                  <b-icon icon="files"></b-icon>
                </b-button>
              </template>
              <template v-else>
                <b-button
                  variant="secondary"
                  size="sm"
                  class="edit-btn mr-1 p-0"
                  disabled
                >
                  <b-icon icon="files"></b-icon>
                </b-button>
              </template>
              <b-button
                variant="danger"
                size="sm"
                class="delete-btn p-0"
                v-if="allows_crud && !Question.is_locked"
                tabindex="-1"
                v-b-tooltip.top.noninteractive.v-secondary="'Eliminar Pregunta'"
                @click="askForDeleteQuestion()"
              >
                <!-- v-can="'evaluations2.delete_evaluation'" -->
                <b-icon-trash></b-icon-trash>
              </b-button>
            </div>
          </div>
        </div>

        <!-- Container Body -->
        <div class="container-body">
          <div
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            class="description-question rich-text-content"
            :class="{
              'description-container': allows_crud && !Question.is_locked,
              'unselectable-text':
                (user && user.groups.includes(7)) || preview_evaluation,
            }"
            v-html="Question.description"
            v-b-tooltip.top.noninteractive.v-secondary="
              `${
                allows_crud && !Question.is_locked ? 'Editar Descripción' : ''
              }`
            "
            @click="showModalDescriptionQuestion()"
          ></div>
          <div class="separator mb-2 mt-1"></div>

          <div class="limit-words-section" v-if="Question.question_type == 1">
            <LimitQuestion
              :Question="Question"
              :QuestionScore="EvaluateeQuestionScore"
              :allows_crud="allows_crud && !Question.is_locked"
              :preview_evaluation="preview_evaluation"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
              :user_id="user_id"
            ></LimitQuestion>
          </div>

          <div v-else-if="Question.question_type == 2">
            <SelectQuestionOptions
              class="ml-3"
              :Question="Question"
              :QuestionScore="EvaluateeQuestionScore"
              :allows_crud="allows_crud && !Question.is_locked"
              :preview_evaluation="preview_evaluation"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
              :user_id="user_id"
            >
            </SelectQuestionOptions>
          </div>
          <div v-else-if="Question.question_type == 3">
            <FillingQuestion
              :Question="Question"
              :allows_crud="allows_crud && !Question.is_locked"
              :preview_evaluation="preview_evaluation"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
            >
            </FillingQuestion>
          </div>
          <div v-else-if="Question.question_type == 4">
            <MatchingQuestion
              :Question="Question"
              :container_instrument_id="container_instrument_id"
              :QuestionScore="EvaluateeQuestionScore"
              :allows_crud="allows_crud && !Question.is_locked"
              :preview_evaluation="preview_evaluation"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
              :user_id="user_id"
            >
            </MatchingQuestion>
          </div>
          <div
            v-if="
              evaluator_view &&
              user_id &&
              Question.linked_rubric_criteria != null
            "
          >
            <RubricsContainer
              class="ml-4 mr-4"
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :container_instrument_id="container_instrument_id"
              :instrument_id="Question.test"
              :allows_crud="false"
              :preview_evaluation="preview_evaluation"
              :evaluator_view="evaluator_view"
              :user_id="user_id"
              :closedEvaluationScore="closedEvaluationScore"
              :requests_within_the_component="false"
              :filter_rubric_instance="Question.linked_rubric_criteria"
            ></RubricsContainer>
          </div>
          <div
            class="separator mb-3"
            :class="{ 'mt-2': Question.question_type == 2 }"
          ></div>
        </div>
        <!-- Container Footer -->
        <div>
          <div class="container-footer">
            <div class="d-flex">
              <div class="score-input-container" v-if="!checked_score">
                <div class="mr-1"><strong>Puntaje:</strong></div>
                <b-form-input
                  v-if="allows_crud && !Question.is_locked"
                  class="mr-3"
                  :class="{
                    'score-input-warning': questionsMaxScore > test.max_score,
                    'score-input-warning-minor':
                      questionsMaxScore < test.max_score,
                  }"
                  v-b-tooltip.top.noninteractive.v-danger="
                    `${
                      questionsMaxScore > test.max_score
                        ? `El puntaje de las preguntas es mayor que el puntaje de la ${$getVisibleNames(
                            'evaluations2.test',
                            true,
                            'Instrumento'
                          )}`
                        : questionsMaxScore < test.max_score
                        ? `El puntaje de las preguntas es menor que el puntaje de la ${$getVisibleNames(
                            'evaluations2.test',
                            true,
                            'Instrumento'
                          )}`
                        : ''
                    }`
                  "
                  v-model="Question.max_score"
                  @input="patchQuestionScore"
                  type="number"
                  min="0"
                ></b-form-input>
                <div v-else class="mr-2">{{ Question.max_score }}</div>
              </div>
              <div class="d-flex mr-2" v-if="!checked_weightning">
                <strong class="mr-2">Ponderación:</strong>
                <div
                  v-if="
                    institution &&
                    institution.internal_use_id == 'duoc_uc' &&
                    Question.linked_rubric_criteria != null &&
                    rubricsCriteria &&
                    allows_crud
                  "
                  :class="{
                    'text-danger':
                      questionsWeighingRubricCriteria !=
                      rubricsCriteria.weighing,
                  }"
                  v-b-tooltip.top.noninteractive.v-info="
                    `${
                      questionsWeighingRubricCriteria > rubricsCriteria.weighing
                        ? 'Se excedió del ' +
                          rubricsCriteria.weighing +
                          '% asignado en el IE, aproximadamente en: ' +
                          suggestedScore * -1
                        : questionsWeighingRubricCriteria <
                          rubricsCriteria.weighing
                        ? 'Actualmente tiene disponible ' +
                          parseFloat(
                            (
                              rubricsCriteria.weighing -
                              questionsWeighingRubricCriteria
                            ).toFixed(2)
                          ) +
                          '% para el IE seleccionado, en puntaje es: ' +
                          suggestedScore
                        : ''
                    }`
                  "
                >
                  {{ questionsWeighing }}%
                </div>
                <div v-else>{{ questionsWeighing }}%</div>
              </div>
              <div class="question-type-container">
                <div class="question-type mr-2">
                  <strong> Tipo de pregunta:</strong>
                </div>
                <div
                  :class="{
                    'text-danger':
                      (!enable_question_types.includes(
                        Question.question_type
                      ) ||
                        (Question.question_type == 2 &&
                          !enable_question_sub_types.includes(
                            Question.subtype
                          ))) &&
                      allows_crud,
                  }"
                  v-b-tooltip.top.noninteractive.v-danger="
                    `${
                      (!enable_question_types.includes(
                        Question.question_type
                      ) ||
                        (Question.question_type == 2 &&
                          !enable_question_sub_types.includes(
                            Question.subtype
                          ))) &&
                      allows_crud
                        ? 'El Tipo de pregunta no pertenece a la ' +
                          $getVisibleNames(
                            'teaching.evaluationsituation',
                            false,
                            'Situación Evaluativa'
                          ) +
                          '.'
                        : ''
                    }`
                  "
                >
                  {{ questionTypes() }}
                  <template v-if="Question.question_type == 2">
                    <span v-if="Question.subtype == 2"> Múltiple</span>
                    <span v-else-if="Question.subtype == 1"> Simple</span>
                  </template>
                </div>
              </div>
            </div>
            <template v-if="evaluator_view && user_id">
              <div class="d-flex">
                <div
                  v-if="
                    Question.question_type == 2 || Question.question_type == 4
                  "
                  class="score-input-container ml-3"
                >
                  <div class="mr-1">
                    <strong>Puntaje Automático: </strong>
                    {{
                      evaluatee_question_score.automated_score != null
                        ? evaluatee_question_score.automated_score
                        : 0
                    }}
                  </div>
                </div>
                <div class="score-input-container ml-3">
                  <div class="mr-1">
                    <strong>Puntaje Obtenido:</strong>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-form-input
                      :class="{
                        'border-warning text-warning':
                          evaluatee_question_score.manual_score >
                          Question.max_score,
                        'border-danger text-danger':
                          evaluatee_question_score.manual_score < 0,
                      }"
                      :id="`tooltip-target-manual_score-${Question.id}-${
                        Question.order
                      }-${testSegment ? testSegment.order : ''}`"
                      class="mr-3"
                      style="max-width: 80px"
                      v-model="evaluatee_question_score.manual_score"
                      :disabled="!closedEvaluationScore"
                      @input="patchEvaluateeScore"
                      type="number"
                      min="0"
                    ></b-form-input>
                    <b-tooltip
                      :target="`tooltip-target-manual_score-${Question.id}-${
                        Question.order
                      }-${testSegment ? testSegment.order : ''}`"
                      v-if="evaluatee_question_score.manual_score < 0"
                      variant="danger"
                      triggers="hover"
                    >
                      El puntaje no debe ser menor que 0
                    </b-tooltip>
                    <b-tooltip
                      :target="`tooltip-target-manual_score-${Question.id}-${
                        Question.order
                      }-${testSegment ? testSegment.order : ''}`"
                      v-else-if="
                        evaluatee_question_score.manual_score >
                        Question.max_score
                      "
                      variant="warning"
                      triggers="hover"
                    >
                      El puntaje asignado es mayor que el de la pregunta
                    </b-tooltip>
                  </div>
                </div>
                <div class="score-input-container ml-1 mr-1">
                  <b-button
                    v-if="
                      evaluator_view &&
                      closedEvaluationScore &&
                      (evaluatee_question_score.feedback == null ||
                        evaluatee_question_score.feedback.trim().length == 0)
                    "
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Agregar Retroalimentación'
                    "
                    tabindex="-1"
                    @click="showModalQuestionFeedback()"
                  >
                    <b-icon icon="chat-left-text-fill"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
            <template
              v-if="
                !evaluator_view &&
                !evaluatee_view &&
                allows_crud &&
                !Question.is_locked &&
                [1].includes(Question.question_type) &&
                Question.proposed_answer.trim().length == 0
              "
            >
              <div class="d-flex noprint">
                <div class="score-input-container ml-3 mr-1">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Añadir Respuesta Correcta'
                    "
                    tabindex="-1"
                    @click="showModalProposedAnswerQuestion()"
                  >
                    <b-icon icon="file-check-fill"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
            <template
              v-if="
                !evaluator_view &&
                !evaluatee_view &&
                allows_crud &&
                !Question.is_locked &&
                [2].includes(Question.question_type)
              "
            >
              <div class="d-flex noprint">
                <div
                  class="score-input-container ml-3"
                  v-if="Question.correct_alternative_message.trim().length == 0"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Añadir Respuesta Correcta'
                    "
                    tabindex="-1"
                    @click="showModalCorrectMessageQuestion()"
                  >
                    <b-icon icon="file-check-fill"></b-icon>
                  </b-button>
                </div>
                <div
                  class="score-input-container mr-1"
                  v-if="
                    Question.incorrect_alternative_message.trim().length == 0
                  "
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Añadir Respuesta Incorrecta'
                    "
                    tabindex="-1"
                    @click="showModalIncorrectMessageQuestion()"
                  >
                    <b-icon icon="file-excel-fill"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="
              (!evaluator_view &&
                !evaluatee_view &&
                allows_crud &&
                [1].includes(Question.question_type) &&
                Question.proposed_answer.trim().length != 0) ||
              (evaluator_view &&
                !evaluatee_view &&
                [1].includes(Question.question_type) &&
                Question.proposed_answer.trim().length != 0)
            "
            class="noprint"
          >
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="d-flex">
                    <b-button
                      block
                      v-b-toggle="
                        `collapse-${Question.test}-${Question.question_type}-${Question.id}`
                      "
                      tabindex="-1"
                      variant="info"
                      >Respuesta Correcta</b-button
                    >
                    <b-button
                      v-if="!evaluator_view && !evaluatee_view"
                      class="ml-1 p-1"
                      variant="danger"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Limpiar Respuesta Correcta`
                      "
                      @click="clearCorrectAnswer"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`collapse-${Question.test}-${Question.question_type}-${Question.id}`"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="p-0">
                    <b-card-text>
                      <div
                        class="w-100 rich-text-content pl-2 pr-2 pt-2 pb-3"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `${
                            allows_crud && !Question.is_locked
                              ? `Editar la Respuesta Correcta`
                              : ''
                          }`
                        "
                        :class="{
                          'title-container': allows_crud && !Question.is_locked,
                        }"
                        @click="showModalProposedAnswerQuestion()"
                        v-html="Question.proposed_answer"
                      ></div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div
            v-if="
              [2].includes(Question.question_type) &&
              Question.correct_alternative_message.trim().length != 0 &&
              ((!evaluator_view && !evaluatee_view && allows_crud) ||
                (evaluator_view && !evaluatee_view) ||
                (evaluatee_view && is_published && finished_test))
            "
            class="noprint"
          >
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="d-flex">
                    <b-button
                      block
                      v-b-toggle="
                        `collapse-correct_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`
                      "
                      tabindex="-1"
                      variant="info"
                      >Respuesta Correcta</b-button
                    >
                    <b-button
                      v-if="!evaluator_view && !evaluatee_view"
                      class="ml-1 p-1"
                      variant="danger"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Limpiar Respuesta Correcta`
                      "
                      @click="clearCorrectMessage"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`collapse-correct_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="p-0">
                    <b-card-text>
                      <div
                        class="w-100 rich-text-content pl-2 pr-2 pt-2 pb-3"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `${
                            allows_crud && !Question.is_locked
                              ? `Editar la Respuesta Correcta`
                              : ''
                          }`
                        "
                        :class="{
                          'title-container': allows_crud && !Question.is_locked,
                        }"
                        @click="showModalCorrectMessageQuestion()"
                        v-html="Question.correct_alternative_message"
                      ></div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div
            v-if="
              [2].includes(Question.question_type) &&
              Question.incorrect_alternative_message.trim().length != 0 &&
              ((!evaluator_view && !evaluatee_view && allows_crud) ||
                (evaluator_view && !evaluatee_view) ||
                (evaluatee_view &&
                  is_published &&
                  finished_test &&
                  ((evaluatee_question_score &&
                    ((evaluatee_question_score.final_score != null &&
                      evaluation.final_score != Question.max_score) ||
                      (evaluatee_question_score.final_score == null &&
                        evaluatee_question_score.manual_score != null &&
                        evaluatee_question_score.manual_score !=
                          Question.max_score) ||
                      (evaluatee_question_score.final_score == null &&
                        evaluatee_question_score.manual_score == null &&
                        evaluatee_question_score.automated_score != null &&
                        evaluatee_question_score.automated_score !=
                          Question.max_score))) ||
                    isNaN(evaluatee_question_score))))
            "
            class="noprint"
          >
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="d-flex">
                    <b-button
                      block
                      v-b-toggle="
                        `collapse-incorrect_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`
                      "
                      tabindex="-1"
                      variant="info"
                      >Respuesta Incorrecta</b-button
                    >
                    <b-button
                      v-if="!evaluator_view && !evaluatee_view"
                      class="ml-1 p-1"
                      variant="danger"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Limpiar Respuesta Incorrecta`
                      "
                      @click="clearIncorrectMessage"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`collapse-incorrect_alternative_message-${Question.test}-${Question.question_type}-${Question.id}`"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="p-0">
                    <b-card-text>
                      <div
                        class="w-100 rich-text-content pl-2 pr-2 pt-2 pb-3"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `${
                            allows_crud && !Question.is_locked
                              ? `Editar la Respuesta Incorrecta`
                              : ''
                          }`
                        "
                        :class="{
                          'title-container': allows_crud && !Question.is_locked,
                        }"
                        @click="showModalIncorrectMessageQuestion()"
                        v-html="Question.incorrect_alternative_message"
                      ></div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <!-- feedback -->
          <div
            v-if="
              (evaluator_view &&
                evaluatee_question_score.feedback != null &&
                evaluatee_question_score.feedback.trim().length != 0) ||
              (!evaluator_view &&
                evaluatee_view &&
                is_published &&
                finished_test &&
                evaluatee_question_score.feedback != null &&
                evaluatee_question_score.feedback.trim().length != 0)
            "
            class="noprint"
          >
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="d-flex">
                    <b-button
                      block
                      v-b-toggle="
                        `collapse-feedback-${Question.test}-${Question.question_type}-${Question.id}`
                      "
                      tabindex="-1"
                      variant="info"
                      >Retroalimentación</b-button
                    >
                    <b-button
                      v-if="!evaluatee_view && closedEvaluationScore"
                      class="ml-1 p-1"
                      variant="danger"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Limpiar Retroalimencación`
                      "
                      @click="clearFeedbackTeacher"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`collapse-feedback-${Question.test}-${Question.question_type}-${Question.id}`"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="p-0">
                    <b-card-text>
                      <div
                        v-if="!evaluatee_view && closedEvaluationScore"
                        class="title-container w-100 rich-text-content pl-2 pr-2 pt-2 pb-3"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `${
                            allows_crud && !Question.is_locked
                              ? `Editar la Respuesta Correcta`
                              : ''
                          }`
                        "
                        @click="showModalQuestionFeedback()"
                        v-html="evaluatee_question_score.feedback"
                      ></div>
                      <div
                        v-else-if="!evaluatee_view && !closedEvaluationScore"
                        class="w-100 rich-text-content pl-2 pr-2 pt-2 pb-3"
                        v-html="evaluatee_question_score.feedback"
                      ></div>
                      <div
                        v-if="
                          evaluatee_view &&
                          is_published &&
                          finished_test &&
                          evaluatee_question_score.feedback != null &&
                          evaluatee_question_score.feedback.trim().length != 0
                        "
                      >
                        <div
                          v-html="evaluatee_question_score.feedback"
                          class="rich-text-content p-1 pl-2"
                        ></div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :id="`edit-title-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      :title="`Editar ${$getVisibleNames(
        'manual.test.enunciado',
        false,
        'Enunciado'
      )}`"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="Question"
        :Text="Question.title"
        @save="patchQuestionTitle"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-title-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`edit-description-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      title="Modificar Descripción"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="Question"
        :Text="Question.description"
        @save="patchQuestionDescription"
        :permit_blank="true"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-description-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`edit-proposed_answer-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      title="Modificar la Respuesta Correcta"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="Question"
        :Text="Question.proposed_answer"
        @save="patchQuestionProposedAnswer"
        :permit_blank="true"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-proposed_answer-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`edit-correct_alternative_message-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      title="Modificar la Respuesta Correcta"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="Question"
        :Text="Question.correct_alternative_message"
        @save="patchQuestionCorrectMessage"
        :permit_blank="true"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-correct_alternative_message-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`edit-incorrect_alternative_message-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      title="Modificar la Respuesta Incorrecta"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="Question"
        :Text="Question.incorrect_alternative_message"
        @save="patchQuestionIncorrectMessage"
        :permit_blank="true"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-incorrect_alternative_message-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`edit-feedback-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      title="Modificar Retroalimentación"
      size="lg"
      hide-footer
    >
      <NewRichTextEditor
        :Object="evaluatee_question_score"
        :Text="evaluatee_question_score.feedback"
        @save="patchFeedbackQuestion"
        :permit_blank="true"
        :hide_title="true"
        @close="
          $bvModal.hide(
            `edit-feedback-question-modal-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <b-modal
      :id="`modal-edit-question-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`"
      :title="`Editar ${$getVisibleNames(
        'evaluations2.redactionquestion',
        false,
        'Pregunta'
      )}`"
      hide-footer
      size="lg"
    >
      <NewQuestionForm
        :test_id="Question.test"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :Question="Question"
        :evaluation_situations="evaluation_situations"
        @closeModal="
          $bvModal.hide(
            `modal-edit-question-${Question.test}-${Question.question_type}-${Question.id}-${container_instrument_id}`
          )
        "
      ></NewQuestionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "NewQuestionCard",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    RubricsContainer: () =>
      import("@/components/new-evaluations/Rubrics/RubricsContainer"),
    NewQuestionForm: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/NewQuestionForm"
      ),
    LimitQuestion: () =>
      import("@/components/new-evaluations/Evaluation/Questions/LimitQuestion"),
    SelectQuestionOptions: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/SelectQuestionOptions"
      ),
    FillingQuestion: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/FillingQuestion"
      ),
    MatchingQuestion: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/MatchingQuestion/MatchingQuestion"
      ),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    checked_score: {
      type: Boolean,
    },
    checked_weightning: {
      type: Boolean,
    },
    container_instrument_id: {
      type: String,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    evaluation_situations: {
      type: Array,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    closedEvaluationScore: {
      type: Boolean,
      default: false,
    },
    is_published: {
      type: Boolean,
    },
  },
  data() {
    return {
      question_types: [
        { id: 1, value: "Desarrollo" },
        { id: 2, value: "Selección" },
        // { id: 3, value: "Auto Completar" },
        { id: 4, value: "Emparejamiento" },
      ],
      // limited_words_amount: false,
      evaluatee_question_score: {
        id: generateUniqueId(),
        automated_score: null,
        manual_score: null,
        final_score: null,
        evaluatee: this.user_id,
        feedback: "",
        attachments: [],
      },
      copy_button_tem_check: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      test_segments: names.NEW_TEST_SEGMENTS,
      max_answer_length_types: names.REDACTION_QUESTION_LENGTH_ANSWERS,
      evaluations: names.NEW_EVALUATIONS,
      evaluatee_evaluations: names.EVALUATEE_EVALUATIONS,
      tests: names.NEW_TESTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      evaluatee_redaction_questions: names.EVALUATEE_REDACTION_QUESTIONS,
      evaluatee_multiple_choice_questions:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_matching_questions: names.EVALUATEE_MATCHING_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      rubrics_criterias: names.NEW_RUBRIC_CRITERIAS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.Question.test);
    },
    evaluateeEvaluationScore() {
      if (!this.test) return null;
      return this.evaluatee_evaluations.find(
        (x) =>
          x.evaluation == this.test.evaluation && x.evaluatee == this.user_id
      );
    },
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    enable_question_types() {
      if (!this.evaluationSituation) return [1, 2, 4];
      else {
        let question_enable = [];
        if (this.evaluationSituation.enable_redaction_questions)
          question_enable.push(1);
        if (
          this.evaluationSituation.enable_simple_selection_questions ||
          this.evaluationSituation.enable_multiple_selection_questions
        )
          question_enable.push(2);
        if (this.evaluationSituation.enable_matching_questions)
          question_enable.push(4);
        return question_enable;
      }
    },
    enable_question_sub_types() {
      if (!this.evaluationSituation) return [1, 2];
      else {
        let question_sub_types_enable = [];
        if (this.evaluationSituation.enable_simple_selection_questions)
          question_sub_types_enable.push(1);
        if (this.evaluationSituation.enable_multiple_selection_questions)
          question_sub_types_enable.push(2);
        return question_sub_types_enable;
      }
    },
    questionsMaxScore() {
      let score = 0;
      this.questions.forEach((element) => {
        if (
          element.id == this.Question.id &&
          element.question_type == this.Question.question_type
        ) {
          if (this.Question.max_score != "" && this.Question.max_score != null)
            score += parseFloat(this.Question.max_score);
        } else score += parseFloat(element.max_score);
      });
      return score;
    },
    EvaluateeQuestionScore() {
      if (this.Question.question_type == 1)
        return this.evaluatee_redaction_questions.find(
          (x) =>
            x.evaluatee == this.user_id &&
            x.redaction_question == this.Question.id
        );
      else if (this.Question.question_type == 2)
        return this.evaluatee_multiple_choice_questions.find(
          (x) =>
            x.evaluatee == this.user_id &&
            x.multiple_choice_question == this.Question.id
        );
      else if (this.Question.question_type == 4)
        return this.evaluatee_matching_questions.find(
          (x) =>
            x.evaluatee == this.user_id &&
            x.matching_question == this.Question.id
        );
      else return null;
    },
    questions() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
    redactionQuestions() {
      return this.redaction_questions.filter(
        (x) => x.test == this.Question.test
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.Question.test
      );
    },
    fillingQuestions() {
      return this.filling_questions.filter((x) => x.test == this.Question.test);
    },
    matchingQuestions() {
      return this.matching_questions.filter(
        (x) => x.test == this.Question.test
      );
    },
    // maxAnswerLengthType() {
    //   let max_answer_length_types = [...this.max_answer_length_types];
    //   max_answer_length_types.push({ id: null, value: "N/A" });
    //   return max_answer_length_types;
    // },
    questionsWeighing() {
      let maxScore = 0;
      if (this.test) maxScore = this.test.max_score;
      if (maxScore == 0) return 0;
      return parseFloat(
        ((parseFloat(this.Question.max_score) / maxScore) * 100).toFixed(2)
      );
    },
    rubricsCriteria() {
      if (this.Question.linked_rubric_criteria == null) return null;
      return this.rubrics_criterias.find(
        (x) => x.id == this.Question.linked_rubric_criteria
      );
    },
    questionsWeighingRubricCriteria() {
      const questions_weighing = this.questionsWeighing;
      if (!this.rubricsCriteria || !this.test) return questions_weighing;
      const rubrics_criteria = this.rubricsCriteria;
      let questions_criteria = this.questions.filter(
        (x) => x.linked_rubric_criteria == rubrics_criteria.id
      );
      if (questions_criteria.length == 0) return questions_weighing;
      else {
        const questions_scores = questions_criteria.reduce(
          (acc, a) => acc + parseFloat(a.max_score),
          0
        );
        return parseFloat(
          ((questions_scores / this.test.max_score) * 100).toFixed(2)
        );
      }
    },
    suggestedScore() {
      if (
        this.Question.linked_rubric_criteria != null &&
        this.rubricsCriteria
      ) {
        const correct_weighing = this.questionsWeighingRubricCriteria;
        const rubrics_criteria_weighing = this.rubricsCriteria.weighing;
        let max_score = 0;
        if (this.test) max_score = this.test.max_score;
        if (max_score == 0) return this.Question.max_score;
        return parseFloat(
          (
            ((rubrics_criteria_weighing - correct_weighing) * max_score) /
            100
          ).toFixed(2)
        );
      } else return parseFloat(this.Question.max_score);
    },
    testSegment() {
      if (this.Question.segment == null) return null;
      return this.test_segments.find((x) => x.id == this.Question.segment);
    },
  },
  methods: {
    copyQuestion() {
      this.copy_button_tem_check = true;
      if (this.Question.question_type == 1) {
        this.$restful
          .Get(
            `/evaluations2/copy_redaction_question_in_test/?redaction_question=${this.Question.id}&test=${this.Question.test}`
          )
          .then((question_id) => {
            this.$store
              .dispatch(names.FETCH_NEW_REDACTION_QUESTION, question_id)
              .then(() => {
                this.copy_button_tem_check = false;
                toast("Pregunta Duplicada");
              });
          });
      } else if (this.Question.question_type == 2) {
        this.$restful
          .Get(
            `/evaluations2/copy_multiple_choice_question_in_test/?multiple_choice_question=${this.Question.id}&test=${this.Question.test}`
          )
          .then((question_id) => {
            this.$store
              .dispatch(names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION, question_id)
              .then(() => {
                this.copy_button_tem_check = false;
                toast("Pregunta Duplicada");
              });
          });
      } else if (this.Question.question_type == 4) {
        this.$restful
          .Get(
            `/evaluations2/copy_matching_question_in_test/?matching_question=${this.Question.id}&test=${this.Question.test}`
          )
          .then((question_id) => {
            this.$store
              .dispatch(names.FETCH_NEW_MATCHING_QUESTION, question_id)
              .then(() => {
                this.copy_button_tem_check = false;
                toast("Pregunta Duplicada");
              });
          });
      }
    },
    changeStateQuestion() {
      this.patchQuestion({ is_locked: !this.Question.is_locked });
    },
    askForDeleteQuestion() {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la pregunta?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (this.Question.question_type == 1) {
            this.$store.dispatch(
              names.DELETE_NEW_REDACTION_QUESTION,
              this.Question.id
            );
          } else if (this.Question.question_type == 2) {
            this.$store.dispatch(
              names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION,
              this.Question.id
            );
          } else if (this.Question.question_type == 3) {
            this.$store.dispatch(
              names.DELETE_NEW_FILLING_QUESTION,
              this.Question.id
            );
          } else if (this.Question.question_type == 4) {
            this.$store.dispatch(
              names.DELETE_NEW_MATCHING_QUESTION,
              this.Question.id
            );
          }
          toast("Se eliminó la pregunta ");
        }
      });
    },
    patchQuestion(item) {
      if (this.Question.question_type == 1) {
        const payload = {
          new_redaction_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_REDACTION_QUESTION, payload);
      } else if (this.Question.question_type == 2) {
        const payload = {
          new_multiple_choice_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION, payload);
      } else if (this.Question.question_type == 3) {
        const payload = {
          new_filling_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_FILLING_QUESTION, payload);
      } else if (this.Question.question_type == 4) {
        const payload = {
          new_matching_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_MATCHING_QUESTION, payload);
      }
      toast("Pregunta actualizada.");
    },
    questionTypes() {
      const questionType = this.question_types.find(
        (x) => x.id == this.Question.question_type
      );
      if (questionType) return questionType.value;
      else return "";
    },
    patchQuestionScore(value) {
      if (value >= 0 && value != null && value != "") {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.patchQuestion({ max_score: value });
        }, 1000);
      }
    },
    showModalQuestion() {
      if (this.allows_crud && !this.Question.is_locked)
        this.$bvModal.show(
          `edit-title-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
        );
    },
    showModalDescriptionQuestion() {
      if (this.allows_crud && !this.Question.is_locked)
        this.$bvModal.show(
          `edit-description-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
        );
    },
    showModalProposedAnswerQuestion() {
      if (this.allows_crud && !this.Question.is_locked)
        this.$bvModal.show(
          `edit-proposed_answer-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
        );
    },
    showModalCorrectMessageQuestion() {
      if (this.allows_crud && !this.Question.is_locked && !this.evaluatee_view)
        this.$bvModal.show(
          `edit-correct_alternative_message-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
        );
    },
    showModalIncorrectMessageQuestion() {
      if (this.allows_crud && !this.Question.is_locked && !this.evaluatee_view)
        this.$bvModal.show(
          `edit-incorrect_alternative_message-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
        );
    },
    showModalQuestionFeedback() {
      this.$bvModal.show(
        `edit-feedback-question-modal-${this.Question.test}-${this.Question.question_type}-${this.Question.id}-${this.container_instrument_id}`
      );
    },
    patchQuestionTitle(question, text) {
      this.patchQuestion({ title: text });
    },
    patchQuestionDescription(question, text) {
      this.patchQuestion({ description: text });
    },
    patchQuestionProposedAnswer(question, text) {
      this.patchQuestion({ proposed_answer: text });
    },
    patchQuestionCorrectMessage(question, text) {
      this.patchQuestion({ correct_alternative_message: text });
    },
    patchQuestionIncorrectMessage(question, text) {
      this.patchQuestion({ incorrect_alternative_message: text });
    },
    patchFeedbackQuestion(question, text) {
      this.patchEvaluateeQuestion({ feedback: text });
    },
    clearFeedbackTeacher() {
      this.patchEvaluateeQuestion({ feedback: "" });
    },
    clearCorrectAnswer() {
      this.patchQuestion({ proposed_answer: "" });
    },
    clearCorrectMessage() {
      this.patchQuestion({ correct_alternative_message: "" });
    },
    clearIncorrectMessage() {
      this.patchQuestion({ incorrect_alternative_message: "" });
    },
    changeEvaluateeTest() {
      if (this.user_id && this.test) {
        this.$store.dispatch(names.FETCH_EVALUATEE_TESTS, {
          evaluatee_id: this.user_id,
          test_id: this.instrument_id,
        });
        this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
          evaluatee_id: this.user_id,
          evaluation_id: this.test.evaluation,
        });
      }
    },
    patchEvaluateeScore(value) {
      if (this.closedEvaluationScore)
        if (value >= 0 && value != null && value != "") {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            if (value >= 0 && value != null && value != "") {
              if (
                this.evaluateeEvaluationScore &&
                this.test &&
                this.test.weighing > 0 &&
                (this.evaluateeEvaluationScore.final_grade != null ||
                  this.evaluateeEvaluationScore.manual_grade != null)
              ) {
                this.$swal({
                  title: "¿Está seguro de cambiar el puntaje de la pregunta?",
                  html: "cuando la nota de la evaluación ya está fijada, o desea cambiar la nota a <strong>automática</strong> para que se recalcule en base a lo que está modificando?",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Estoy seguro/a",
                  cancelButtonText: "Mantener la nota asignada",
                }).then((result) => {
                  if (result.value) {
                    const payload = {
                      evaluatee_evaluation_id: this.evaluateeEvaluationScore.id,
                      item: {
                        manual_grade: null,
                        final_grade_grade: null,
                      },
                    };
                    this.$store
                      .dispatch(names.PATCH_EVALUATEE_EVALUATION, payload)
                      .then(() => {
                        this.patchEvaluateeQuestion({ manual_score: value });
                      });
                  } else {
                    this.patchEvaluateeQuestion({ manual_score: value });
                  }
                });
              } else {
                this.patchEvaluateeQuestion({ manual_score: value });
              }
            }
          }, 1000);
        }
    },
    patchEvaluateeQuestion(item) {
      if (
        !isNaN(this.evaluatee_question_score.id) ||
        this.EvaluateeQuestionScore != null
      ) {
        if (this.Question.question_type == 1) {
          const payload = {
            evaluatee_redaction_question_id: this.EvaluateeQuestionScore.id,
            item: item,
          };
          this.$store
            .dispatch(names.PATCH_EVALUATEE_REDACTION_QUESTION, payload)
            .then(() => {
              this.changeEvaluateeTest();
            });
        } else if (this.Question.question_type == 2) {
          const payload = {
            evaluatee_multiple_choice_question_id:
              this.EvaluateeQuestionScore.id,
            item: item,
          };
          this.$store
            .dispatch(names.PATCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION, payload)
            .then(() => {
              this.changeEvaluateeTest();
            });
        } else if (this.Question.question_type == 4) {
          const payload = {
            evaluatee_matching_question_id: this.EvaluateeQuestionScore.id,
            item: item,
          };
          this.$store
            .dispatch(names.PATCH_EVALUATEE_MATCHING_QUESTION, payload)
            .then(() => {
              this.changeEvaluateeTest();
            });
        }
      } else if (isNaN(this.evaluatee_question_score.id)) {
        if (this.Question.question_type == 1) {
          this.$store
            .dispatch(names.POST_EVALUATEE_REDACTION_QUESTION, {
              automated_score: null,
              manual_score: item.manual_score ? item.manual_score : null,
              final_score: null,
              answer: "",
              feedback: item.feedback ? item.feedback : "",
              evaluatee: this.user_id,
              redaction_question: this.Question.id,
              chosen_rubric: null,
              attachments: [],
            })
            .then((response) => {
              this.evaluatee_question_score = response;
              this.changeEvaluateeTest();
            });
        } else if (this.Question.question_type == 2) {
          this.$store
            .dispatch(names.POST_EVALUATEE_MULTIPLE_CHOICE_QUESTION, {
              automated_score: null,
              manual_score: item.manual_score ? item.manual_score : null,
              final_score: null,
              feedback: item.feedback ? item.feedback : "",
              evaluatee: this.user_id,
              multiple_choice_question: this.Question.id,
              attachments: [],
            })
            .then((response) => {
              this.evaluatee_question_score = response;
              this.changeEvaluateeTest();
            });
        } else if (this.Question.question_type == 4) {
          this.$store
            .dispatch(names.POST_EVALUATEE_MATCHING_QUESTION, {
              automated_score: null,
              manual_score: item.manual_score ? item.manual_score : null,
              final_score: null,
              feedback: item.feedback ? item.feedback : "",
              evaluatee: this.user_id,
              matching_question: this.Question.id,
              attachments: [],
            })
            .then((response) => {
              this.evaluatee_question_score = response;
              this.changeEvaluateeTest();
            });
        }
      }
      toast("Pregunta actualizada.");
    },
  },
  watch: {
    EvaluateeQuestionScore() {
      this.evaluatee_question_score = this.EvaluateeQuestionScore;
      if (
        this.EvaluateeQuestionScore.automated_score != null &&
        this.EvaluateeQuestionScore.manual_score == null
      ) {
        this.evaluatee_question_score.manual_score =
          this.EvaluateeQuestionScore.automated_score;
      }
    },
  },
  mounted() {
    if (this.EvaluateeQuestionScore != null)
      this.evaluatee_question_score = this.EvaluateeQuestionScore;
  },
  created() {
    // if (
    //   this.Question.question_type == 1 &&
    //   this.Question.max_answer_length_type != null
    // )
    //   this.limited_words_amount = true;
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.score-print {
  display: none;
}
.weightning-print {
  display: none;
}
.unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.score-input-container {
  display: flex;
}
.score-input-warning {
  border: 1px solid #dc3545;
  color: red;
}
.score-input-warning-minor {
  border: 1px solid #dc3545;
  color: red;
}
.question-type-container {
  display: flex;
}
input {
  --bottom-border-color: var(--kl-menu-color);
  max-height: 25px;
  max-width: 52px;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.form-control {
  padding: 0 !important;
}
input:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.invalid {
  border: 1px solid red;
}
.container-score {
  display: flex;
  flex-direction: column;
}
.line-score {
  border: 1px solid #c9c9c9;
}
.title-container:hover {
  background: #eee;
  cursor: pointer;
}
.description-question {
  padding-left: 1rem;
  padding-right: 1rem;
}
.description-container:hover {
  background: #eee;
  cursor: pointer;
}
.separator {
  border: 1px solid #c9c9c9;
  margin-right: 2rem;
  margin-left: 2rem;
}
.limit-words-section {
  margin-left: 1rem;
  text-align: left;
}
.container-buttons-allow-cruds {
  display: flex;
  padding: 5px;
}
.delete-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.delete-btn:hover {
  background-color: #e45d6b;
}
.lock-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.edit-btn:hover {
  background-color: var(--kl-primary-button-hover-color);
}
.edit-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.container-content-question {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 520px;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.container-header {
  display: flex;
  flex-direction: column;
}
.container-body {
  display: flex;
  flex-direction: column;
}
.container-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
.container-body-card {
  display: flex;
  flex-direction: row;
}
.question-container {
  display: flex;
  border-radius: 3px;
  margin: 10px 0px 0px 0px;
  box-shadow: 0px 2px 8px -3px var(--secondary-color);
  border: 1px solid #c9c9c9;
  transition: all 0.3s ease-out;
  overflow: hidden;
}
.question-container:hover {
  box-shadow: 0px 4px 12px -2px var(--secondary-color);
}
.question-body {
  background-color: white;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.row-div {
  width: 40px;
}
.max-width {
  width: 100%;
  border-radius: 5px;
}
.question-move {
  background: var(--primary-color);
  width: 35px;
  display: flex;
}
.question-move:hover {
  box-shadow: 0px 4px 12px -2px var(--secondary-color);
  background-color: var(--kl-menu-color);
  color: white;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.move-icon {
  margin: auto;
  cursor: grab;
}
.evaluator-input {
}
@media print {
  .max-width-print {
    width: 100% !important;
    border-radius: 5px;
  }
  .question-container {
    border: none;
  }
}
@media (max-width: 750px) {
  label {
    max-width: 160px;
  }
  .container-footer {
    flex-direction: column;
  }
  .question-type-container {
    margin-top: 0rem;
  }
  .question-container {
    overflow-x: auto;
  }
}
</style>

